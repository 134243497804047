import { defineComponent, ref } from "vue";
import Dropdown from "../dropdown/index.vue";
import Accordion from "../accordion/index.vue";
import router from "../../router";

export default defineComponent({
  name: "Header",
  components: { Dropdown, Accordion },
  setup() {
    const isNavOpen = ref(false);
    const closedAccordion = ref(false);
    const isDropDownOpen = ref(false);
    const activeLinks = ref([]);

    const investInNasarawaLinks = [
      {
        name: "Why Nasarawa",
        link: "/why-nasarawa",
        description:
          "The state’s strategic location and business friendly clime attracts enterpreneurs and many multinational companies to situate their offices in the State.",
      },
      {
        name: "Investment Opportunities",
        link: "/investment-opportunities",
        description:
          "We are continously adopting innovative approaches to collaborate with private partners and development finance institutes to facilitate sectorial development within the state.",
      },
      {
        name: "DOING BUSINESS HERE",
        link: "/doing-business",
        // subLinks:[
        //   {
        //     name: "REGISTRATION OF BUSINESS PERMIT",
        //     link: "",
        //   },
        //   {
        //     name: "LAND REGISTRATION",
        //     link: "",
        //   },
        //   {
        //     name: "CONSTRUCTION AND BUILDING PERMITS",
        //     link: "",
        //   },
        // ],
      },
      {
        name: "Sectors",
        link: "/#investment-opportunities",
      },
      {
        name: "PPP",
        link: "https://ppp.nasida.na.gov.ng",
        external: true
      },
      
    ];
    
    const aboutUsLinks = [
      {
        name: "Who We Are",
        link: "/who-we-are",
      },
      {
        name: "Our Governance",
        link: "/our-governance",
      },
      {
        name: "Management Team",
        link: "/management-team",
      },
      {
        name: "Economic Advisory Council",
        link: "/economic-advisory-council",
      },
      {
        name: "Departments",
        link: "/departments",
      },
    ];

    const resources = [
      {
        name: "PRESS RELEASE",
        link: "/press-releases",
      },
      // {
      //   name: "PUBLICATIONS",
      //   link: "",
      // },
      // {        
      //   name: "GALLERY",
      //   link: "",
      // },
      // {
      //   name: "LAWS",
      //   link: "",
      // },
      // {
      //   name: "DOCUMENTS",
      //   link: "",
      // },
      {
        name: "INCENTIVES INVENTORY",
        link: "/incentives",
      },
      {
        name: "SUMMIT REPORT",
        link: "/summit-report",
      },
      {
        name: "DOCUMENTS",
        link: "/documents",
      },
      {
        name: "BUSINESS REFORMS 2023",
        link: "/business-reforms-2023",
      },
      {
        name: "BUSINESS RELATED REGULATIONS",
        link: "/business-related-regulations",
      },
      {
        name: "Business Enabling Reforms",
        link: "/business-enabling-reforms",
      },
      {
        name: "NASIDA 2021-2024 Report",
        link: "/nasida-2021-2024-report",
      },
      // {
      //   name: "Procedures for Approvals",
      //   link: "/procedures-for-approvals",
      // },
    ];

    const events = [
      {
        name: "NIS",
        link: "/investment-summit",
      },
      // {
      //   name: "NASBET",
      //   link: "/nasbet",
      // },
    ];

    return {
      isNavOpen,
      closedAccordion,
      isDropDownOpen,
      activeLinks,
      investInNasarawaLinks,
      aboutUsLinks,
      resources,
      events,
    };
  },
  methods: {
    toggleNavigation() {
      this.isNavOpen = !this.isNavOpen;
      this.closedAccordion = true;
    },
    closeNavigation() {
      this.isNavOpen = false;
      this.closedAccordion = true;
    },
    goHome() {
      router.push({ name: "Home" });
      this.closeNavigation();
    },
    openDropDown(links) {
      this.isDropDownOpen = true;
      this.activeLinks = links;
    },
    closeDropDown() {
      if (this.isDropDownOpen == true) {
        this.isDropDownOpen = false;
      }
    },
    goto(url) {
      window.open(url);
    },
  },
});
