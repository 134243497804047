<template>
  <div
    v-click-outside="close"
    id="dropdown"
    :class="{ 'mt-0': active, '-mt-56': !active }"
  >
    <div id="dropdown-content">
      <div v-for="link in links" :key="link.name">
        <div v-if="link.subLinks">
          <AnchorLink
            @click="close"
            :text="link.name"
            :url="link.link"
            :isDropDownLink="true"
            :showArrow="false"
          />
          <hr class="w-full" />

          <div
            v-click-outside="close"
            id="dropdown2"
            :class="{ 'mt-0': active, '-mt-56': !active }"
          >
            <div id="dropdown-content drop2" class="px-2 ">
              <div v-for="subLink in link.subLinks" :key="subLink.name">
                <AnchorLink
                  @click="close"
                  :text="subLink.name"
                  :url="subLink.link"
                  :isDropDownLink="true"
                  :showArrow="false"
                  :isSub="true"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="link.name == 'PPP'">
            <DownloadLinkk :text="link.name" :url="link.link" @click="close" />
            <!-- <AnchorLinkk
          @click="close"
          :text="link.name"
          :url="link.link"
          :isDropDownLink="true"
        /> -->
          </div>
          <div v-else>
            <AnchorLink
              @click="close"
              :text="link.name"
              :url="link.link"
              :isDropDownLink="true"
            />
          </div>
        </div>
        <hr class="w-full" />
        <p
          class="text-gray-600 text-justify mt-1"
          style="font-size: 13px;"
          v-if="link.description"
        >
          {{ link.description }}
        </p>
      </div>
    </div>
  </div>
</template>
<script src="./dropdown.component.js" lang="js"></script>
<style src="./dropdown.component.css" scoped lang="css"></style>
