const load = (component) => {
    return () =>
        import (`@/pages/${component}/index.vue`);
};

export const mainRoutes = [
  {
    path: "/",
    name: "Nasarawa Investment & Development Agency",
    meta: { requiresAuth: false, layout: 'default' },
    component: load("home"),
  },
  {
    path: "/home-page",
    name: "Home page",
    meta: { requiresAuth: false, layout: 'default' },
    component: load("home-page"),
  },
  {
    path: "/investment-summit",
    name: "Investment Summit",
    meta: { requiresAuth: false, layout: 'default' },
    component: load("investment-summit"),
  },
  {
    path: "/why-nasarawa",
    name: "Why Nasarawa",
    meta: { requiresAuth: false, layout: 'default' },
    component: load("why-nasarawa"),
  },
  {
    path: "/investment-opportunities",
    name: "Investment Opportunities",
    meta: { requiresAuth: false, layout: 'default' },
    component: load("investment-opportunities"),
  },
  {
    path: "/who-we-are",
    name: "Who We Are",
    meta: { requiresAuth: false, layout: 'default' },
    component: load("who-we-are"),
  },
  {
    path: "/our-governance",
    name: "Our Governance",
    meta: { requiresAuth: false, layout: 'default' },
    component: load("our-governance"),
  },
  {
    path: "/management-team",
    name: "Management Team",
    meta: { requiresAuth: false, layout: 'default' },
    component: load("management-team"),
  },
  {
    path: "/economic-advisory-council",
    name: "Economic Advisory Council",
    meta: { requiresAuth: false, layout: 'default' },
    component: load("economic-advisory-council"),
  },
  {
    path: "/sector/:name",
    name: "Sector",
    meta: { requiresAuth: false, layout: 'default' },
    component: load("sector"),
    props: true,
  },
  {
    path: "/sector",
    redirect: "/sector/agriculture",
  },
  {
    path: "/governor",
    name: "Governor",
    meta: { requiresAuth: false, layout: 'default' },
    component: load("profile"),
  },
  {
    path: "/managing-director",
    name: "Managing Director & Chief Executive Officer",
    meta: { requiresAuth: false, layout: 'default' },
    component: load("profile"),
  },
  {
    path: "/departments",
    name: "Departments",
    meta: { requiresAuth: false, layout: 'default' },
    component: load("departments"),
  },
  {
    path: "/infrastructure-and-thematic-holdings",
    name: "Infrastructure & Thematic Holdings",
    meta: { requiresAuth: false, layout: 'default' },
    component: load("infrastructure"),
  },
  {
    path: "/factsheet",
    name: "Factsheet",
    meta: { requiresAuth: false, layout: 'default' },
    component: load("factsheet"),
  },
  {
    path: "/nsukstudentaccommodation",
    name: "NSUK Student Accommodation",
    meta: { requiresAuth: false, layout: 'default' },
    component: load("nsukstudentaccommodation"),
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    meta: { requiresAuth: false, layout: 'default' },
    component: load("contact-us"),
  },
  {
    path: "/press-releases",
    name: "Latest Press Releases",
    meta: { requiresAuth: false, layout: 'default' },
    component: load("press-releases"),
  },
  {
    path: "/press-releases/:id",
    name: "Press Release Detail",
    meta: { requiresAuth: false, layout: 'default' },
    component: load("press-release-detail"),
  },
  {
    path: "/summit-report",
    name: "Summit Report",
    meta: { requiresAuth: false, layout: 'default' },
    component: load("summit-report"),
  },
  {
    path: "/documents",
    name: "Documents",
    meta: { requiresAuth: false, layout: 'default' },
    component: load("documents"),
  },
  {
    path: "/business-reforms-2023",
    name: "Business Reforms 2023",
    meta: { requiresAuth: false, layout: 'default' },
    component: load("business-reforms-2023"),
  },
  {
    path: "/business-related-regulations",
    name: "Business Related Regulations",
    meta: { requiresAuth: false, layout: 'default' },
    component: load("business-related-regulations"),
  },
  {
    path: "/business-enabling-reforms",
    name: "Business-Enabling Reforms",
    meta: { requiresAuth: false, layout: 'default' },
    component: load("business-enabling-reforms"),
  },
  {
    path: "/nasida-2021-2024-report",
    name: "NASIDA 2021-2024 Report",
    meta: { requiresAuth: false, layout: 'default' },
    component: load("nasida-2021-2024-report"),
  },
  {
    path: "/ppp",
    name: "PUBLIC PRIVATE PARTNERSHIPS",
    meta: { requiresAuth: false, layout: 'default' },
    component: load("ppp"),
  },
  // {
  //   path: "/procedures-for-approvals",
  //   name: "Procedures for Approvals",
  //   meta: { requiresAuth: false, layout: 'default' },
  //   component: load("procedures-for-approvals"),
  // },
  {
    path: "/doing-business",
    name: "Doing Business",
    meta: { requiresAuth: false, layout: 'default' },
    component: load("doing-business"),
  },
  {
    path: "/incentives",
    name: "Incentive Inventory",
    meta: { requiresAuth: false, layout: 'default' },
    component: load("incentive"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Error",
    meta: { requiresAuth: false, layout: 'default' },
    component: load("error"),
  },

];

export const constructionRoutes = [
  {
    path: "/",
    name: "Under Construction - Nasarawa Investment & Development Agency",
    meta: { requiresAuth: false, layout: 'default' },
    component: load("construction"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: '/',
  },
];
