<template>

  <div
    class="flex items-center"
    :class="{
      'text-white': color == 'white',
      'text-nasida-green': color == 'green',
      'dropdownlink': isDropDownLink
    }"
  >
    <a
      class="flex items-center"
      :href="url"
      onclick="javascipt:window.open(url)"
      terget="__blank"
    >
      <span
        class="link-text "
        :class="{'text-sm': isDropDownLink, 'spaced': !isDropDownLink, 'sub_link':isSub}"
      >{{ text }}</span>
  </a>
  </div>
</template>

<style src="./download-linkk.component.css" scoped lang="css"></style>
<script src="./download-linkk.component.js"></script>