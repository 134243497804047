<template>
  <div id="press-releases" v-if="pressReleases[pressReleases.length - 1]">
    <div class="sub_headings text-6xl"><b>Latest</b> News</div>

    <div class="flex justify-between items-center mt-5 headline">
      <div class="w-4/5 img">
        <img
          class="w-20 object-cover"
          style="width: 50%;"
          src="../../assets/images/press.png"
        />
      </div>
      <div class="dets pl-10">
        <div class="heading font-bold text-5xl">
          Nasarawa Climate Investment Platform Announcement
        </div>
        <div class="my-6 date">December 17, 2024</div>
        <!-- <AnchorLinkk
          text="Read more"
          url="https://api.nasida.na.gov.ng/services/Nasarawa%20Climate%20Investment%20Platform%20Announcement.pdf"
        /> -->
        <!-- <DownloadLink :text="linkText" :url="link" /> -->
        <div class="py-2 flex items-center text-white text-nasida-green dropdownlink" style="font-size:0.75rem;">
          <a
            class="flex items-center"
            href="https://api.nasida.na.gov.ng/services/Nasarawa%20Climate%20Investment%20Platform%20Announcement.pdf"
            target="_blank"
          >
            <span class="link-text text-xs spaced sub_link">Read More</span>
          </a>

          <div class="w-10 arrow">
            <svg
              fill="none"
              viewBox="0 0 66 24"
              class="text-xs stroke-current"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 12L61 12"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M54 5L61 12L54 19"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>


      </div>
    </div>

    <div class="flex justify-between items-center mt-20 headline">
      <div class="w-4/5 img">
        <img
          class="w-full object-cover"
          :src="pressReleases[pressReleases.length - 1].image"
        />
      </div>
      <div class="dets pl-10">
        <div class="heading font-bold text-5xl">
          {{ pressReleases[pressReleases.length - 1].title }}
        </div>
        <div class="my-6 date">
          {{ pressReleases[pressReleases.length - 1].created_at }}
        </div>
        <AnchorLink
          text="Read more"
          :url="'/press-releases/' + pressReleases[pressReleases.length - 1].id"
        />
      </div>
    </div>

    <div class="grid grid-cols-1 md:grid md:grid-cols-3 gap-6 mt-20">
      <PressRelease
        v-for="pressRelease in pressReleases.slice(0, pressReleases.length - 1)"
        :key="pressRelease.id"
        :post="pressRelease"
      />
    </div>
    <div class="text-center" v-if="nextPage">
      <button
        @click="loadMore()"
        class="mt-14 p-6 px-20 bg-nasida-green text-xl text-white text-center"
      >
        LOAD MORE
      </button>
    </div>
  </div>
</template>

<script src="./press-releases.page.js"></script>
<style src="./press-releases.page.css" scoped lang="css"></style>
