import { defineComponent } from "vue";

import Hero from "@/components/hero/index.vue";
import SummitLink from "@/components/summit-link/index.vue";
import heroBg from "../../assets/images/about-us-bg.png";

export default defineComponent({
  name: "Summit Report",
  components: { SummitLink, Hero },
  setup() {
    const heroSlide = [
      {
        title: "Summit Report",
        image: heroBg,
      },
    ];

    return {
      heroSlide,
    };
  },
  data() {
    return {
      filesrcs1: [
        { title: 'Nasarawa Investment Summit 2022.', src: 'https://www.dropbox.com/s/rx1qvrxpq41deq0/Nasarawa%20Investment%20Summit%202022%20Report.pdf?dl=0' },
        
      ],
      filesrcs: [
        { title: 'Nasarawa Investment Summit 2024.', src: 'https://api.nasida.na.gov.ng/services/NASIDA/08-01-2025/Nasarawa Investment Summit 2024.pdf' },
        { title: 'Nasarawa Investment Summit 2022.', src: 'https://api.nasida.na.gov.ng/downloads/Nasarawa-Investment-Summit-2022-Report' },
        
      ],
    }
  }, 
  methods: {
    goto(url) {
      window.open(url);
    },
  },

  // methods: {
  //   forceFileDownload(response, title) {
  //     console.log(title)
  //     const url = window.URL.createObjectURL(new Blob([response.data]))
  //     const link = document.createElement('a')
  //     link.href = url
  //     link.setAttribute('download', title)
  //     document.body.appendChild(link)
  //     link.click()
  //   },
  //   downloadWithAxios(url, title) {
  //     console.log(url);
  //     axios({
  //       method: 'get',
  //       url,
  //       // responseType: 'arraybuffer',
  //     })
  //       .then((response) => {
  //         this.forceFileDownload(response, title)
  //       })
  //       .catch(() => console.log('error occured'))
  //   },
  // }
});
