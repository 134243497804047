import { defineComponent } from "vue";
import vClickOutside from "click-outside-vue3";
import AnchorLink from "../anchor-link/index.vue";
import AnchorLinkk from "../anchor-linkk/index.vue";
import DownloadLinkk from "../download-linkk/index.vue";

export default defineComponent({
  name: "Dropdown",
  components: { AnchorLink, AnchorLinkk, DownloadLinkk },
  props: {
    links: {
      type: Array,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
});
